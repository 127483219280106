<!--
 * @Author: John
 * @Date: 2022-02-22 11:45:19
 * @LastEditTime: 2022-09-23
 * @LastEditors: Please set LastEditors
 * @Description: 交易记录
 * @FilePath: \jjms_ui\src\views\trans\list.vue
 * @Copyright John
-->
<template>
    <div>
        <my-breadcrumb :data="breadcrumbData" />
        <div class="content-wrap">
            <div class="toolbar">
                <el-form :inline="true">
                    <jj-select v-model="jjSelectObj" />
                    <el-form-item>
                        <el-date-picker
                            v-model="queryDateRange"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                            </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="query" icon="el-icon-search" style="margin-left: 20px;">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="toAddPage" icon="el-icon-document-add" style="margin-left: 20px;">添加</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="toEditPage" icon="el-icon-edit-outline">编辑</el-button>
                    </el-form-item>
                    <el-form-item>
                        <span style="color:red; margin-left: 30px;">默认查询近三个月记录（系统最多只展示50条数据）</span>
                    </el-form-item>
                </el-form>
            </div>

            <el-table
                ref="transTable"
                :data="tableData"
                style="width: 100%"
                highlight-current-row
                @current-change="handleCurrentChange"
                >
                <el-table-column
                    width="50"
                    type="index"
                    label="序号"
                 />
                <el-table-column
                    prop="jjCode"
                    label="基金代码"
                    width="80"
                />
                <el-table-column
                    prop="jjName"
                    label="基金名称"
                />
                <el-table-column
                    prop="transactionType"
                    label="交易类型"
                    width="80"
                    :formatter="formatTransactionType" 
                />
                <el-table-column
                    prop="transactionMoney"
                    label="申购/赎回金额"
                    width="80" 
                />
                <el-table-column
                    prop="networth"
                    label="当日净值"
                    width="100" 
                />
                <el-table-column
                    prop="fundShare"
                    label="确认/赎回份额"
                    width="100" 
                />
                <el-table-column
                    prop="handlineFee"
                    label="手续费"
                    width="80" 
                />
                <el-table-column
                    prop="confirmMoney"
                    label="确认/到账金额"
                    width="100" 
                />
                <el-table-column
                    prop="status"
                    label="状态"
                    width="80"
                    :formatter="formatStatusShow"
                />
                <el-table-column
                    prop="transactionDate"
                    label="交易日期"
                    width="120" 
                />
                <el-table-column
                    prop="confirmDate"
                    label="份额确认日期"
                    width="120" 
                />
                <el-table-column
                    prop="totalMoney"
                    label="累计投入金额"
                    width="120" 
                />
                <el-table-column
                    prop="totalFoundShare"
                    label="持有份额"
                    width="100" 
                />
                <el-table-column
                    prop="totalMarketValue"
                    label="总市值"
                    width="80" 
                />
                <el-table-column
                    prop="createtime"
                    label="添加时间" 
                />
            </el-table>
        </div>

        <el-dialog :title="formTitle" :visible.sync="dialogFormVisible" width="40%">
            <add-trans-form ref="addTransForm" :data.sync="editFormData" :isDialog="true" />
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addTrans">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import myBreadcrumb from '@/components/my-breadcrumb.vue'
import addTransForm from '@/components/add-transaction.vue'

import api from '../../api/req_api'
import JjSelect from '../../components/jj-select.vue'


export default {
    name: 'trans',
    components: {
        myBreadcrumb,
        addTransForm,
        JjSelect
    },
    data() {
        return {
            breadcrumbData: [
                {title: '交易管理'},
                {title: '交易记录', url: '/main/trans-list'}
            ],
            tableData: [],
            currentRow: null,
            dialogFormVisible: false,
            editFormData: null,
            formTitle: '',
            jjSelectObj: null,
            queryDateRange:null
        }
    },
    methods: {
        // 格式化交易状态字段显示
        formatStatusShow(row, column, cellValue) {
            let text = '';
            cellValue+='';
            switch(cellValue) {
                case '1':
                    text = '确认中';
                    break;
                case '2':
                    text = '已确认';
                    break;
                default:
                    break;
            }
            return text;
        },
        // 格式化交易类型
        formatTransactionType(row, column, cellValue) {
            let text = '';
            cellValue += '';
            switch(cellValue) {
                case '1':
                    text = '申购';
                    break;
                case '2':
                    text = '赎回';
                    break;
                default:
                    break;
            }
            return text;
        },
        // 表格行选中事件
        handleCurrentChange(currentRow) {
            this.currentRow = currentRow;
        },
        // 打开新增定投窗口
        toAddPage() {
            this.editFormData = null;
            console.log('打开新增窗口， this.editFormData: ' + this.editFormData)
            this.formTitle = '添加交易记录';
            this.dialogFormVisible = true;
        },
        // 打开编辑定投窗口
        toEditPage() {
            if(this.currentRow === null) {
                this.$message.warning('请选择一条数据！');
                return;
            }
            this.editFormData = this.currentRow;
            this.formTitle = '修改交易记录';
            this.dialogFormVisible = true;
        },
        // 保存定投
        addTrans() {
            this.$refs['addTransForm'].submit(() => {
                this.$message.info("保存成功！");
                this.dialogFormVisible = false;
                this.query();
            });
        },
        query() {

            let startDate, endDate;
            if(this.queryDateRange) {
                startDate = this.queryDateRange[0];
                endDate = this.queryDateRange[1];
            }
            this.loadData(startDate, endDate, this.jjCode);
        },
        loadData(startDate, endDate, jjCode) {
            // 请求数据
            api.queryTransList(startDate, endDate, jjCode)
                .then(data => {
                    if(data != null) {
                        this.tableData = data;
                    }
                })
        }
    },
    computed: {
        jjCode() {
            if(!this.jjSelectObj) return '';
            return this.jjSelectObj.jjCode;
        }
    }
}
</script>
